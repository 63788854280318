const userPrefix = '/sams/user'
const pre = '/v1/sams/'
const userApiPrefix = '/v1/sams/sams-user/'
const activityPrefix = '/v1/sams/activity'
export const api = {
  auth: {
    checkKey: {
      url: '/v1/sams/sams-user/authorization/key'
    },
    /** 获取灰度配置 */
    getGrayConfig: {
      url: `/v1/sams/configuration/portal/getGrayConfig`
    }
  },
  getMemberInfo: {
    url: '/v1/sams/sams-user/user/personal_center_info',
    config: {
      method: 'GET'
    }
  },
  wxSDK: {
    getJsApiTicket: {
      url: `${userApiPrefix}authorization/get_jsapi_ticket`,
      config: {}
    }
  },

  getShortUrl: { url: `v1/sams/configuration/portal/shorturl/getShortUrl` }, // 获取短链

  // 登录注册接口
  session: {
    // 静默授权 无需鉴权
    login: {
      url: `${userPrefix}/core/wxLogin`,
      config: {
        forceLogin: false,
        throwWhileBizCodeError: true
      }
    },
    // 微信渠道用户账号登录
    accountLogin: {
      url: `${userPrefix}/core/accountLogin`,
      config: { throwWhileBizCodeError: true }
    },
    // 获取手机号验证码
    getPhoneCode: {
      url: `${userPrefix}/verification/code/getPhoneCode`,
      config: { throwWhileBizCodeError: true }
    }
  },
  user: {
    // 获取用户的权益列表
    getRedeemCodes: {
      url: 'v1/sams/sams-user/user/get_redeem_codes',
      config: { method: 'GET' }
    },
    // 获取生活权益中心配置
    getEquityCenterConfig: {
      url: 'v1/sams/sams-user/membership/get_equity_center_config',
      config: { method: 'GET' }
    },
    // 权益下线详情
    getEquityOfflineDetail: {
      url: 'v1/sams/sams-user/membership/get_equity_offline_detail',
      config: { method: 'GET' }
    },
    // 获取续费升级信息
    getRenewInfo: {
      url: 'v1/sams/sams-user/membership/trans/get_renew_info',
      config: { method: 'GET' }
    },
    // 获取续费类型列表
    getRenewTypeList: {
      url: 'v1/sams/sams-user/membership/trans/get_renew_type_list_new',
      config: { method: 'GET' }
    },
    sendCode: {
      url: `${userApiPrefix}authorization/send_code`,
      config: {}
    },
    login: {
      url: `${userApiPrefix}authorization/mobile`,
      config: {}
    },
    addressList: {
      url: `${userApiPrefix}receiver_address/address_list`,
      config: { method: 'GET' }
    },
    // 实名认证
    certifiName: {
      url: `${userApiPrefix}user/real_name`,
      config: {
        hideErrorToast: true
      }
    },
    // 实名认证-校验验证码
    certifiPhone: {
      url: `${userApiPrefix}user/real_name/check_code`,
      config: {
        hideErrorToast: true
      }
    },
    // 实名认证-获取验证码
    getSmsCode: {
      url: `${userApiPrefix}user/real_name/sendRealNameCode`
    },
    // 获取其它有效卡
    getEffectiveMember: {
      url: `${userApiPrefix}membership/get_effective_member`,
      config: {
        method: 'GET'
      }
    },
    // 冻结亲友卡
    freezeGiftCard: {
      url: `${userApiPrefix}membership/freeze_gift_card`
    },
    // 获取ABTest上报信息
    getAbTestInfo: {
      url: `${userApiPrefix}abtest/getGrayReportInfo?expName=oneCard`,
      config: {
        method: 'GET'
      }
    },
    // 一人一卡后端文案
    getOneCardText: {
      url: `${userApiPrefix}user/one_card_text`
    },
    getHkmemberInfo: {
      url: `${userApiPrefix}user/get_hk_mc_buy_card_info`,
      config: {}
    },
    // 获取会员信息以及是否联名卡
    getMemberInfoAndIsCoBranded: {
      url: 'v1/sams/sams-user/user/get_member_info_and_is_co_branded',
      config: { method: 'GET' }
    }
  },
  goods: {
    detail: {
      url: `${pre}goods-portal/spu/queryDetailForH5`,
      config: {}
    },
    scanCode: {
      url: `${pre}goods-portal/spu/scanCode`,
      config: {}
    },
    getPublicWorksheet: {
      url: `${pre}goods-portal/samsDemo/getPublicWorksheet`,
      config: {}
    },
    excuteApiQuery: {
      url: `${pre}goods-portal/samsDemo/excuteApiQuery`,
      config: {}
    },
    addRow: {
      url: `${pre}goods-portal/samsDemo/addRow`,
      config: {}
    },
    getWorksheetInfo: {
      url: `${pre}goods-portal/samsDemo/getWorksheetInfo`,
      config: {}
    },
    getFilterRows: {
      url: `${pre}goods-portal/samsDemo/getFilterRows`,
      config: {}
    }
  },
  membership: {
    // 查询会员头像
    getMemberHeadUrl: {
      url: `${userApiPrefix}membership/get_member_head_url`,
      config: { method: 'get' }
    },
    giftCardGet: {
      url: `${userApiPrefix}membership/gift_card/get_gift_card`,
      config: {}
    },
    registerGiftCard: {
      url: `${userApiPrefix}membership/gift_card/register`,
      config: {}
    },
    cardInfo: {
      url: `${userApiPrefix}membership/mem_card_page_info`,
      config: { method: 'get' }
    },
    memberInfo: {
      url: `${userApiPrefix}user/member_card_info`,
      config: { method: 'get' }
    },
    getRenewTypeList: {
      url: `${userApiPrefix}membership/trans/get_renew_type_list_new`,
      config: { method: 'get', level: 3 }
    },
    getRenewCoupon: {
      url: `${userApiPrefix}membership/trans/get_renew_list_copy_new`,
      config: { method: 'get', level: 3 }
    },
    buyMember: {
      url: `${userApiPrefix}membership/trans/buy`,
      config: {}
    },
    getOpenId: {
      url: `${userApiPrefix}authorization/wechat_web_auth`,
      config: {}
    },
    parseCode: {
      url: `${userApiPrefix}invite/parse_code`,
      config: { method: 'get' }
    },
    transResult: {
      url: `${userApiPrefix}membership/trans/result`,
      config: {}
    },
    queryPayCallbackInfo: {
      url: `${pre}trade/order/queryPayCallbackInfo`,
      config: {}
    },
    customerServiceConf: {
      // 客服电话
      url: `${pre}configuration/customerServiceConf/query`,
      config: {}
    },
    checkRealName: {
      // 用户实名校验
      url: `${userApiPrefix}/user/check_real_name`,
      config: {
        hideErrorToast: true
      }
    },
    checkInLbs: {
      // 校验用户是否在LBS定位中
      url: `${userApiPrefix}/user/check_lbs_locate`,
      config: {
        hideErrorToast: true
      }
    },
    getOpenCardOptions: {
      // 获取用户当前经纬度 开卡选项
      url: `${userApiPrefix}/user/get_buy_member_card_id_type_tables`,
      config: {
        hideErrorToast: true
      }
    },
    exchange: {
      // 兑换码兑换
      url: `${activityPrefix}/exchange-code/exchange`,
      config: {
        hideErrorToast: true
      }
    },
    getMemberInfo: {
      // 获取会员信息
      url: `${userApiPrefix}user/get_member_info`,
      config: { method: 'get' }
    },
    getWxchectCode: {
      // 公众号授权登录获取会员信息
      url: `${userApiPrefix}authorization/wechat_web_auth_get_user_detail`,
      config: {}
    },
    getIdCard: {
      url: `${userApiPrefix}user/ocr/idCard`,
      config: {}
    },
    getRenewSaveMoney: {
      // 获取续费页省钱金额
      url: `${userApiPrefix}user/get_renew_save_money`,
      config: {}
    },
    getUserReward: {
      // 获取reward金额
      url: `${userApiPrefix}reward/get_co_branded_info`,
      config: { method: 'get' }
    },
    getEquityCenterConfig: {
      // 获取权益配置
      url: `${userApiPrefix}membership/get_equity_center_config`,
      config: { method: 'get' }
    },
    getDowngradeRenewList: {
      // 获取降级续费类型列表
      url: `${userApiPrefix}membership/trans/get_downgrade_renew_type_list`,
      config: { method: 'get' }
    },
    getDowngradeRenewCopy: {
      // 降级续费-获取续费类型优惠券信息
      url: `${userApiPrefix}membership/trans/get_downgrade_renew_copy`,
      config: { method: 'get' }
    },
    // 查询用户当前会籍申请单
    getNotesInfo: {
      url: `${userApiPrefix}membership/request_notes/info`,
      config: { method: 'get' }
    },
    // 查看申请原因列表
    getMemberRightsReasons: {
      url: `${userApiPrefix}membership/request_notes/get_member_rights_reasons`,
      config: {}
    },
    // 获取退卡验证码
    getVerificationCode: {
      url: `${userApiPrefix}membership/request_notes/send_validation_code`,
      config: {}
    },
    // 提交会籍申请单
    submitNote: {
      url: `${userApiPrefix}membership/request_notes/submit`,
      config: { level: 3 }
    },
    // 续费/升级校验非风险/高风险接口
    renewCheck: {
      url: `${userApiPrefix}membership/renew_check`,
      config: { method: 'get' }
    },
    // 获取风险会员认证文案
    getRiskAuthCopy: {
      url: `${userApiPrefix}risk_member/risk_auth_copy`,
      config: { method: 'get' }
    },
    // 查询会员退卡提示浮窗
    queryReturnCardFloatingWindow: {
      url: `${pre}/sams-user/membership/query_return_card_floating_window`
    },
    // 亲友卡转主卡校验
    checkTransferMainCard: {
      url: `${userApiPrefix}membership/trans/checkTransferMainCard`,
      config: {}
    }
  },
  channel: {
    queryAdgroup: {
      // 广告查询
      url: `${pre}channel/portal/AdgroupData/queryAdgroup`,
      config: {}
    }
  },
  store: {
    getStorePickUpSiteList: {
      // 查询门店
      url: `${pre}/delivery/portal/getStorePickUpSiteList`,
      config: {}
    },
    // 根据门店ID查询门店
    getStoreInfoListByStoreIds: {
      url: `${pre}/merchant/storeApi/getStoreInfoListByStoreIds`,
      config: {}
    },
    // 根据经纬度查询最近门店
    getMiniUnLoginStoreList: `${pre}/merchant/storeApi/getMiniUnLoginStoreList` // 未登录--根据定位获取推荐门店列表
  },
  address: {
    allCityList: {
      url: `${pre}merchant/addressApi/getAllCityDevision`,
      config: {}
    },
    searchNear: {
      url: `${pre}merchant/addressApi/searchNear`,
      config: {}
    },
    searchDivisionByLocation: {
      url: `${pre}/merchant/addressApi/searchDivisionByLocation`,
      config: {}
    },
    searchAddress: {
      url: `${pre}/merchant/addressApi/searchByKeyWord`,
      config: {}
    }
  },
  saveMoney: {
    // 查询个人会籍省钱计算器信息
    saveMoneyInfoGeneral: {
      url: 'v1/sams/sams-user/membership/trans/general/save_money_info',
      config: { method: 'GET' }
    },
    // 查询卓越会籍省钱计算器信息
    saveMoneyInfoPlus: {
      url: 'v1/sams/sams-user/membership/trans/plus/save_money_info',
      config: { method: 'GET' }
    },
    // 省钱计算器续费或升级会籍
    renew: {
      url: 'v1/sams/sams-user/membership/trans/renew'
    },
    // 查询省钱无忧页面信息
    saveWorryInfo: {
      url: 'v1/sams/sams-user/membership/trans/save_worry_info',
      config: { method: 'GET', hideErrorToast: true }
    },
    // 省钱无忧页面续费
    saveWorryRenew: {
      url: 'v1/sams/sams-user/membership/trans/save_worry_renew_new',
      config: {
        hideErrorToast: true
      }
    },
    // 获取续费类型列表
    getRenewType: {
      url: '/v1/sams/sams-user/membership/trans/get_renew_type_list',
      config: { method: 'GET' }
    },
    // 获取续费升级信息
    getRenewInfo: {
      url: '/v1/sams/sams-user/membership/trans/get_renew_info',
      config: { method: 'GET' }
    },
    // 取消自动续费
    cancelAutoRenew: {
      url: '/v1/sams/sams-user/membership/trans/auto_renew/un_sign',
      config: { method: 'GET' }
    },
    // 请求支付结果
    fetchPayStatus: {
      url: '/v1/sams/sams-user/membership/trans/result'
    },
    // 请求用户cash余额
    getUserCash: {
      url: `/v1/sams/sams-user/cash/get_user_cash`
    },
    // 获取会员续费提醒弹窗
    renewWindow: {
      url: '/v1/sams/sams-user/membership/get_member_renew_window',
      config: { method: 'GET' }
    },
    // 获取续费优惠
    getDiscountAmount: {
      url: '/v1/sams/sams-user/membership/trans/get_renew_list_copy',
      config: { method: 'GET' }
    }
  },

  pointQuery: {
    // 查询用户积分
    count: {
      url: '/v1/sams/sams-user/integral/count'
    },
    // 查询用户积分明细
    detail: {
      url: '/v1/sams/sams-user/integral/detail'
    },
    // 查询积分券
    queryUserCoupon: {
      url: '/v1/sams/sams-user/coupon/queryUserCoupon'
    },
    // 查询月积分
    monthCount: {
      url: '/v1/sams/sams-user/integral/monthCount'
    },
    // cash 升级提示
    fetchSwitch: {
      url: `/v1/sams/sams-user/user/get_integral_switch_new`,
      config: { method: 'GET' }
    }
  },
  task: {
    // 任务去完成按钮的上报
    taskReport: {
      url: `${pre}activity/taskreport`,
      config: { level: 3, throwWhileBizCodeError: true }
    },

    // 获取活动详情
    queryDetail: {
      url: `${pre}activity/getActivityDetail`,
      config: { level: 3 }
    },
    // 查询中奖信息  轮播用
    getLatestPrizeRecord: {
      url: `${pre}activity/getLatestPrizeRecord`,
      config: { level: 3 }
    },
    // 领取印章
    updateActivityTask: {
      url: `${pre}activity/updateActivityTask`
    },
    // 查询奖品信息
    queryStageState: {
      url: `${pre}activity/queryStageState`,
      config: { level: 3, throwWhileBizCodeError: true }
    },

    // 查询奖品门店
    queryPrizeStoreIds: {
      url: `${pre}activity/queryPrizeStoreIds`,
      config: { level: 3, throwWhileBizCodeError: true }
    },

    // 获取用户奖品（所有阶段的奖品信息）
    getUserAward: {
      url: `${pre}activity/getUserAward`
    },
    taskreport: {
      url: `${pre}activity/taskreport`
    },
    // 激活任务
    lockTask: {
      url: `${pre}activity/lockTask`
    },
    // 保存实物领取信息
    saveAddress: {
      url: `${pre}activity/saveAddress`
    },
    // 保存实物领取信息
    dd: {
      url: `${pre}activity/lockTask`
      // 会员任务上报分享事件
    }
  },
  order: {
    // 可开发票的订单列表
    queryOrderList: {
      url: `${pre}trade/order/queryOrderListOfInvoice`
      // url: `${pre}trade/order/queryOrderList`
    },
    queryInvoice: {
      url: `${pre}trade/order/queryInvoice`
    },
    // 申请发票
    applyInvoice: {
      url: `${pre}trade/order/applyInvoice`
    },
    // 修改发票
    updateInvoice: {
      url: `${pre}trade/order/updateInvoice`
    },
    // 批量开发票
    batchApplyInvoice: {
      url: `${pre}trade/order/batchApplyInvoice`
    },
    // 获取发票抬头信息
    getUserTitleTaxTate: {
      url: `${pre}trade/invoiec/getUserTitleTaxTate`
    },
    // 保存用户发票抬头信息
    saveUserTitleTaxTate: {
      url: `${pre}trade/order/saveUserTitleTaxTate`
    },
    // 编辑用户发票抬头信息
    editUserTitleTaxTate: {
      url: `${pre}trade/invoiec/editUserTitleTaxTate`
    },
    // 删除用户发票抬头信息
    deleteUserTitleTaxTate: {
      url: `${pre}trade/order/deleteUserTitleTaxTate`
    },
    // 删除用户提交的发票信息
    deleteUserInvoice: {
      url: `${pre}trade/invoiec/cancelInvoice`
    },
    // 获取开票金额  /v1/sams/trade/order/isCanApplyInvoice
    isCanApplyInvoice: {
      url: `${pre}trade/order/isCanApplyInvoice`
    },
    // 获取模糊查询发票抬头
    queryInvoiceScheduleList: {
      url: `${pre}trade/order/queryInvoiceScheduleList`
    },
    // 能否申请增值发票
    isCanAppreciationInvoice: {
      url: `${pre}trade/order/isCanAppreciationInvoice`
    }
  },
  billingHistory: {
    // 查询开票列表
    queryList: {
      url: `${pre}trade/invoiec/queryHistoryInvoice`
    },
    // 查询开票详情
    queryInvoiceByOrderNo: {
      url: `${pre}trade/order/queryInvoice`
    }
  },
  // 根据协议类型获取最新协议，
  queryByAgreementType: {
    url: `${pre}sams-user/agreement/queryByAgreementType`,
    config: {
      forceLogin: false, // 无需鉴权
      throwWhileBizCodeError: true
    }
  },
  fetchShopAgreement: {
    url: `${pre}configuration/portal/shoppingInstructions/get`
  },
  fetchUseTerm: {
    url: `${pre}configuration/portal/useTerm/get`
  },
  // 查询渠道id信息
  queryChannelInfos: {
    url: `${pre}promotion/channel/getChannelTraceById`,
    config: {
      forceLogin: false
    }
  },
  // 年度账单
  annualData: {
    get: {
      url: `${userApiPrefix}annual_data/get`,
      config: {
        method: 'GET'
      }
    },
    isEpr: {
      url: `${userApiPrefix}annual_data/is_epr`,
      config: {
        method: 'GET'
      }
    }
  },
  // 合作相关
  advert: {
    fetchRedeemList: {
      url: `${pre}sams-user/user/get_redeem_codes`,
      config: { method: 'GET' }
    },
    receivePinganEquity: {
      url: `${pre}sams-user/user/pingan/get_redeem_code`
    }
  },
  // 活动相关
  activity: {
    maotaiAct: {
      url: `${pre}activity/mao-tai/getActivityDetail`,
      config: {
        hideErrorToast: true
      }
    },
    maotaiActPreview: {
      url: `${pre}activity/mao-tai/previewActivityDetail`
    }
  },
  queryPrivacy: {
    url: `${pre}sams-user/agreement/queryByAgreementType`,
    config: {
      forceLogin: false
    }
  },
  // 邀请有礼
  inviteActivity: {
    haveActivity: {
      url: `${pre}activity/invite_have_gifts/inviter/query`
    },
    activityDetail: {
      url: `${pre}activity/invite_have_gifts/inviter/detail`
    },
    inviteRecord: {
      url: `${pre}activity/invite_have_gifts/inviter/inviteeRecord`
    },
    giftRecord: {
      url: `${pre}activity/invite_have_gifts/inviter/rewardRecord`
    },
    shareInfo: {
      url: `${pre}activity/invite_have_gifts/inviter/doInvite`
    },
    getWxCode: {
      url: `${pre}configuration/portal/qrcode/getWxQrCodeAndConvert`
    }
  },
  // 体验卡裂变活动
  expCardPromoteActivity: {
    haveActivity: {
      url: `${pre}activity/experience-card-nuclear/getDisplayActivity`
    },
    activityDetail: {
      url: `${pre}activity/experience-card-nuclear/getMasterActivityDetail`
    },
    inviteRecord: {
      url: `${pre}activity/experience-card-nuclear/getInviteeRecord`
    },
    giftRecord: {
      url: `${pre}activity/experience-card-nuclear/getRewardRecord`
    },
    shareInfo: {
      url: `${pre}activity/experience-card-nuclear/doInvite`
    },
    getWxCode: {
      url: `${pre}configuration/portal/qrcode/getWxQrCodeAndConvert`
    },
    getActivityBasicInfo: {
      url: `${pre}activity/experience-card/getActivityBasicInfo`
    }
  },

  // 体验卡日常投放运营
  expCardOperate: {
    activityDetail: {
      url: `${pre}activity/experience-card-launch/getMasterActivityDetail`
    },
    shareInfo: {
      url: `${pre}activity/experience-card-launch/queryShareDetail`
    },
    queryCardList: {
      url: `${pre}activity/experience-card-launch/queryMasterShareCardList`
    },
    queryExpireDay: {
      url: `${pre}activity/experience-card-launch/queryGiftQuantity`
    }
  },

  // 注销账号
  cancellation: {
    checkUserOrder: {
      url: `${userApiPrefix}write_off/check_existing_order `,
      config: {
        method: 'GET',
        hideErrorToast: true
      }
    },
    getUserPhone: {
      url: `${userApiPrefix}user/profile`,
      config: {
        method: 'GET'
      }
    },
    offAccount: {
      url: `${userApiPrefix}write_off/write_off_user_account`,
      config: {
        forceLogin: false,
        hideErrorToast: true
      }
    }
  },
  // N倍积分
  manyIntegralActivity: {
    getAgreement: {
      url: `${pre}sams-user/agreement/manyIntegralActivity/comment`,
      config: { method: 'GET' }
    }
  },
  // 营业资格证
  lifeVoucherLicenseImg: {
    getLicenseImgList: {
      url: `${pre}goods-portal/spu/queryLifeVoucherLicenseImg`,
      config: { method: 'POST' }
    }
  },
  // 短信链接跳转小程序
  message: {
    getMiniURLScheme: {
      url: `${pre}message/portal/wechat/getMiniURLScheme`
    }
  },
  // 中转页根据参数获取跳转详情
  transit: {
    getChannelTraceById: `${pre}promotion/channel/getChannelTraceById` // 根据渠道id获取详情接口
  },
  // 企业采购
  corporateProcurement: {
    enterprisePurchase: `${pre}configuration/portal/enterprisePurchase/get`, // 获取企业采购-页面数据
    formSubmit: `${pre}configuration/portal/purchaseIntention/update`, // 企业信息表单提交
    pageCompanyName: `${pre}configuration/portal/purchaseIntention/pageCompanyName` // 根据用户输入模糊查询企业列表
  },
  // 长链转短链
  shortUrl: {
    getShortUrl: `${pre}configuration/portal/shorturl/getShortUrl`
  },

  cos: {
    getTmpCredential: `${pre}configuration/cos/getTmpCredential`
  },
  // 线下活动
  offLine: {
    // 查询活动列表
    getActivityList: {
      url: `${pre}activity/offline-event/getActivityList`
    },
    // 查询活动详情
    getActivityDetail: {
      url: `${pre}activity/offline-event/getActivityDetail`
    },
    // 查询子活动时间列表
    getSignUpActivityList: {
      url: `${pre}activity/offline-event/getSignUpActivityList`
    },
    // 报名取消报名接口
    userSignUp: {
      url: `${pre}activity/offline-event/userSignUp`,
      config: { level: 3 }
    },
    // 签到接口
    userSignIn: {
      url: `${pre}activity/offline-event/userSignIn`
    },
    // 我的报名
    getUserActivityList: {
      url: `${pre}activity/offline-event/getUserActivityList`
    },
    /** 活动提醒 */
    activityRemind: {
      url: `${pre}activity/offline-event/getUserSignInRemind`
    },
    /** 获取活动门店列表 */
    getActivityStoreList: {
      url: `${pre}merchant/storeApi/getActivityStoreList`
    },
    /** 不鉴权获取活动详情 */
    getActivityDetailWithoutAuth: {
      url: `${pre}activity/offline-event/getActivityDetailWithoutAuth`
    },
    /** 不鉴权获取活动时间列表 */
    getSignUpActivityListWithoutAuth: {
      url: `${pre}activity/offline-event/getSignUpActivityListWithoutAuth`
    },
    /** 待评价活动 */
    getActivityInfo: {
      url: `${pre}activity/offline-event/getActivityInfo`
    },
    /** 评价活动 */
    doComment: {
      url: `${pre}activity/offline-event/doComment`
    }
  },
  // 体验卡新版
  experience: {
    // 查询我的体验卡列表
    getMyExperienceList: {
      url: `${userApiPrefix}experience-card/qualification/query`
    },
    //  查询分享资格接口
    shareCard: {
      url: `${userApiPrefix}experience-card/qualification/create_code`
    },
    // 扫码进入用户查询 是否符合领取资格 获取详情
    fetchScanCheck: {
      url: `${userApiPrefix}experience-card/qr_check`
    },
    //  查询体验卡领取状态
    queryCardStatus: {
      url: `${userApiPrefix}experience-card/result/query`
    },
    //  领取体验卡
    scanGetCard: {
      url: `${userApiPrefix}experience-card/qr/get`
    },
    //  会员卡信息
    getMember: {
      url: `${userApiPrefix}membership/mem_card_info`,
      config: {
        method: 'GET'
      }
    },
    // 查询使用攻略
    ruleDetail: {
      url: `${userApiPrefix}experience-card/store/detail`
    },
    // 创建分享code 主态点击分享
    getShareInfo: {
      url: `${userApiPrefix}experience-card/qualification/create_code`,
      config: {
        method: 'GET'
      }
    },

    // 分享体验卡领取
    getShareCodeCard: {
      url: `${userApiPrefix}experience-card/qualification/share/get`
    },
    //  查询分享code体验卡详情
    queryShareCodeInfo: {
      url: `${userApiPrefix}experience-card/qualification/share_check`
    },
    // 消除红点
    clearStatus: {
      url: `${userApiPrefix}experience-card/qualification/status/clear`
    }
  },
  // AB-test分流
  experiment: {
    report: { url: `${pre}configuration/abtest/portal/report` }
  },
  // 风险认证
  riskMember: {
    // 获取风险会员认证文案
    riskAuthCopy: {
      url: `${pre}sams-user/risk_member/risk_auth_copy`,
      config: {
        method: 'GET'
      }
    },
    // 身份认证
    identityAuth: {
      url: `${pre}sams-user/risk_member/identity_auth`,
      config: {
        hideErrorToast: true
      }
    },
    // 发送绑卡验证码
    sendValidationCode: {
      url: `${pre}sams-user/risk_member/send_validation_code`
    },
    // 身份认证-验证码校验
    identityAuthCheckCode: {
      url: `${pre}sams-user/risk_member/identity_auth_check_code`,
      config: {
        hideErrorToast: true
      }
    },
    // 风险用户确认上传头像
    uploadAvatar: {
      url: `${pre}sams-user/risk_member/upload_avatar`,
      config: {
        hideErrorToast: true
      }
    }
  },
  // 获取下载地址
  getDownloadUrl: {
    url: `${pre}configuration/portal/getDownloadUrl`
  },
  // app版本更新
  appUpdate: { url: `v1/sams/configuration/appVersionUpdate/getAppVersionUpdateInfoById` },
  /** 获取繁体字纠偏配置接口 */
  getTraditionalCnConfig: {
    url: `${pre}configuration/portal/cnConfig/getTraditionalCnConfig`,
    config: {
      forceLogin: false
    }
  },
  // 会员指南
  memberGuide: {
    // 查询会员指南 轮播 大家都在问  图文和弹幕
    queryMemberGuideline: {
      url: `${pre}channel/portal/memberGuide/queryMemberGuideline`,
      config: {
        method: 'GET',
        level: 3
      }
    },

    // 会员文案信息接口
    querymemberGuideInfo: {
      url: `${userApiPrefix}member_guide/copy`,
      config: {
        method: 'GET'
      }
    },
    //  查询会员周期省钱金额
    queryMemberSaveMoney: {
      url: `${userApiPrefix}member_guide/save_money_amount`,
      config: {
        method: 'GET',
        level: 3
      }
    },

    // 查询会员是否同意过协议
    queryUserIsAgree: {
      url: `${userApiPrefix}agreement/check`
    },

    // 保存协议
    saveAgreement: {
      url: `${userApiPrefix}agreement/consent`
    },

    //  查询分享数据
    queryShareInfo: {
      url: `${userApiPrefix}member_guide/save_money_amount/info`,
      config: {
        method: 'GET',
        level: 3
      }
    }
  }
}

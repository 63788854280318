// 线下活动
import { makeAutoObservable } from 'mobx'
import { apis } from '@/common/http-client'
import { RESPONSE_CODE } from '@/config'
import { Toast } from '@/components/Toast'

import { concat } from 'lodash-es'
import dayjs from 'dayjs'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'

export enum ERRORS_TYPE_ENUM {
  /** 没有定位权限，定位为空 */
  NO_ADDRESS = 'no_address',
  /** 没有活动 */
  NO_ACTIVITY = 'no_activity',
  /** 我的报名列表没有活动 */
  NO_MY_ACTIVITY = 'no_my_activity',
  /** 系统异常 */
  SYSTEM_ERROR = 'system_error',
  /** 网络异常  */
  NETWORK_ERROR = 'network_error'
}

/** 我的报名 0可分享  1邀请成功 2已取消 */
export enum MY_LIST_TYPE {
  /** 可分享 */
  SHARE = 0,
  /** 邀请成功 */
  SUCCESS = 1,
  /** 已过期 */
  END = 2
}

/** 我的报名 0可分享  1邀请成功 2已取消 */
export enum SHOPPING_CHANNEL_TYPE {
  /** 可分享 */
  ALL = 0,
  /** 邀请成功 */
  APP = 1,
  /** 已过期 */
  STORE = 2
}

/** 体验卡类型 */
export enum CardType {
  /** 限时活动 */
  LIMIT_TIME = 1,
  /** 常驻活动 */
  PERMANENT = 2
}

/** 门店可用类型 */
export enum STORE_TYPE {
  /** 全部门店可用 */
  ALL = 1,
  /** 部分门店可用 */
  SOME = 0
}

export enum PAGE_NAME {
  LIST = 'list',
  MYLIST = 'myList'
}

export interface ActivityList {
  /** 活动类型 */
  type: CardType
}

class MyExperience {
  /** 页面错误类型集合 */
  errorMaps: Record<PAGE_NAME, ERRORS_TYPE_ENUM | ''> = {
    /** 活动列表 */
    list: '',
    /** 我的报名列表 */
    myList: ''
  }

  lock = false
  /** 我的报名 tab选择 待参与0 已结束 1 */
  myListActiveTab = MY_LIST_TYPE.SHARE
  /** 可分享活动列表 */
  shareList: ActivityList[] = []
  /**  已过期活动列表 */
  endList: ActivityList[] = []
  /**  分享成功列表 */
  successList: ActivityList[] = []

  /** 状态栏高度 */
  statusBarHeight = 0

  listPageMap: Record<string, any> = {
    [MY_LIST_TYPE.SHARE]: 1,
    [MY_LIST_TYPE.SUCCESS]: 1,
    [MY_LIST_TYPE.END]: 1
  }

  endMap: Record<string, any> = {
    [MY_LIST_TYPE.SHARE]: false,
    [MY_LIST_TYPE.SUCCESS]: false,
    [MY_LIST_TYPE.END]: false
  }
  constructor() {
    makeAutoObservable(this)
  }

  /**
   * 获取我的体验卡列表
   */
  async getMyActivityList(isReset = false) {
    if (this.lock) {
      return
    }
    Toast.loading('')
    this.lock = true
    if (isReset) this.listPageMap[this.myListActiveTab] = 1
    const params = {
      pageSize: 10,
      pageNum: this.listPageMap[this.myListActiveTab],
      queryCriteria: {
        status: this.myListActiveTab
      }
    }
    try {
      const { code, data } = await apis.experience.getMyExperienceList(params)
      this.lock = false
      if (code === RESPONSE_CODE.SUCCESS) {
        const { expCardQualificationVos, count } = data
        if (count === 0) {
          this.setErrorMap(PAGE_NAME.MYLIST, ERRORS_TYPE_ENUM.NO_MY_ACTIVITY)
        } else {
          this.setErrorMap(PAGE_NAME.MYLIST, '')
        }

        switch (this.myListActiveTab) {
          case MY_LIST_TYPE.SHARE:
            this.shareList = isReset
              ? [...expCardQualificationVos]
              : concat(this.shareList, expCardQualificationVos)
            break
          case MY_LIST_TYPE.END:
            this.endList = isReset
              ? [...expCardQualificationVos]
              : concat(this.endList, expCardQualificationVos)
            break
          case MY_LIST_TYPE.SUCCESS:
            this.successList = isReset
              ? [...expCardQualificationVos]
              : concat(this.successList, expCardQualificationVos)
            break
        }
        const end = params.pageNum * params.pageSize >= count
        this.endMap[this.myListActiveTab] = end
        Toast.hide()
      } else {
        this.setErrorMap(PAGE_NAME.LIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
      }
    } catch (err) {
      this.lock = false
      Toast.hide()
      this.setErrorMap(PAGE_NAME.MYLIST, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }

  async clearStatus() {
    const params = {
      status: 3
    }
    try {
      const { code } = await apis.experience.clearStatus(params)
      this.lock = false
      if (code === RESPONSE_CODE.SUCCESS) {
        console.log('消除红点')
      }
    } catch (e) {
      console.log(e)
    }
  }

  getShareInfoData(params: any) {
    return apis.experience.getShareInfo(params)
  }

  getNextMyActivityPage() {
    if (!this.endMap[this.myListActiveTab]) {
      this.lock = false
      this.listPageMap[this.myListActiveTab] = this.listPageMap[this.myListActiveTab] + 1
      this.getMyActivityList()
    }
  }

  handleCodeError(code: string, page: PAGE_NAME) {
    if (code === 'InternalError') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    } else if (code === 'NETWORK_ERROR') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.NETWORK_ERROR)
    } else if (code === 'OFFLINE_NOT_TEMPLATE') {
      this.setErrorMap(page, ERRORS_TYPE_ENUM.SYSTEM_ERROR)
    }
  }

  async setStatusBarHeight() {
    const { statusBarHeight } = await getAppDataByBridge(['statusBarHeight'])
    this.statusBarHeight = Number(statusBarHeight)
  }

  /** 更新我的报名tab */
  updateMyListActiveTab(tab: number) {
    this.myListActiveTab = tab
  }

  setErrorMap(page: PAGE_NAME, type: ERRORS_TYPE_ENUM | '') {
    this.errorMaps[page] = type
  }
}

export default new MyExperience()

import {
  checkIDCard,
  checkCertificate,
  checkMTP,
  checkName,
  checkCardNo,
  getAge,
  checkNameNew
} from '@/utils'
import { RESPONSE_CODE, GiftCardStatus, IsMemberEnum, IdType } from '@/config'
import { makeAutoObservable, runInAction } from 'mobx'
import { apis } from '@/common/http-client'
import { Toast } from '@/components/Toast'
import { navigateToByNative } from '@/common/jsBridge/nativeApp'
import queryString from 'query-string'

export interface RegData {
  pubOpenId?: string // 用户公众号OpenId
  memType?: number // 会籍类型
  regType: string // 亲友卡还是邀请好友
  idNo?: string
  idType?: number // 证件类型
  idTypeValue?: number // 组件类型选择的第几个
  firstName?: string // 名字
  lastName?: string // 姓氏
  realName?: string // 姓名  230307迭代新增  废弃了名字与姓氏
  detailAddress?: string // 详细地址
  recommendMemCode?: string // 推荐人会员号
  agree?: boolean // 同意使用条款
  cityName?: string // 市名称
  districtName?: string // 区名称
  provinceName?: string // 省名称
  donorInfo?: string // 亲友卡加密的赠卡人信息串
  inviteCode?: string // 邀请好友的邀请码
  latitude?: string // 纬度
  longitude?: string // 经度
  mobile?: string // 手机号
  streetName?: string // 街道名称
  lastTimeValue?: string // 记录上次注册信息
  permitObj?: any // 用户港澳通行证照片路径
  showIsSubmit?: boolean // 是否展示 港澳图片已提交
  agreementConfirm?: boolean // 同意个人信息协议
  countryCode?: string
}

enum RiskType {
  NON_RISK = 1,
  HIGHT_RISK = 2
}

interface RiskInfo {
  riskCopy: string
  riskType: RiskType
  jumpLink: string
}

class MemberStore {
  regData: RegData = {
    regType: '',
    idNo: '',
    mobile: '',
    cityName: '',
    districtName: '',
    provinceName: '',
    streetName: '',
    idType: 1,
    firstName: '',
    lastName: '',
    realName: '',
    agree: false,
    detailAddress: '',
    idTypeValue: 0,
    permitObj: {
      fontUrl: '',
      fontKey: '',
      backUrl: '',
      backKey: ''
    },
    showIsSubmit: false,
    agreementConfirm: false
  }
  showRiskModal = false // 风险弹窗
  riskInfo = {
    riskCopy: '',
    riskType: RiskType.NON_RISK, // 非风险弹窗，默认非风险
    jumpLink: ''
  }
  constructor() {
    // console.log('init store')
    this.getDataFromSessionStorage()
    makeAutoObservable(this)
  }
  setDataFromSessionStorage() {
    sessionStorage.setItem('__regData', JSON.stringify(this.regData))
  }

  getDataFromSessionStorage() {
    const _regData = sessionStorage.getItem('__regData')
    if (_regData && _regData !== '{}') {
      try {
        this.regData = JSON.parse(_regData)
      } catch (error) {}
    }
  }

  setRegData(regData: RegData) {
    this.regData = regData
    this.setDataFromSessionStorage()
  }
  // 设置regData里的部分值
  changeRegData(data: any) {
    this.regData = { ...this.regData, ...data }
    this.setDataFromSessionStorage()
  }

  setAgree(agree: boolean) {
    const regData = this.regData
    regData.agree = agree
    this.regData = regData
  }

  get btnStatus() {
    const { detailAddress, streetName, agree, recommendMemCode } = this.regData
    if (recommendMemCode && !checkCardNo(recommendMemCode)) {
      return false
    }
    return !!(detailAddress && streetName && agree)
  }

  get nextBtnDisable() {
    const { idNo, idType, mobile, realName, firstName, lastName } = this.regData
    if (idType === IdType.IDCARD) {
      if (!idNo || !idType || !realName || !mobile) {
        return true
      }
      if (!checkName(realName)) {
        return true
      }
    } else {
      if (!idNo || !idType || !firstName || !lastName || !mobile) {
        return true
      }
      if (!checkNameNew(firstName) || !checkNameNew(lastName)) {
        return true
      }
    }

    // 身份证格式校验
    if (idType === IdType.IDCARD) {
      return !checkIDCard(idNo) || getAge(idNo) < 14
    }
    // 回乡证
    if (idType === IdType.CERTIFICATE) {
      return !checkCertificate(idNo)
    }
    // 台胞证
    if (idType === IdType.MTP) {
      return !checkMTP(idNo)
    }

    return false
  }

  get exchangeBtnDisable() {
    const { realName } = this.regData
    if (!realName) {
      return true
    }

    return false
  }

  /** 续费/升级校验非风险/高风险 */
  async checkRenew(entrySource = '') {
    try {
      const { code, data, msg } = await apis.membership.renewCheck()
      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          result = false, // result true-通过，false-不通过
          riskCopy = '',
          riskType = RiskType.NON_RISK,
          jumpLink = ''
        } = data || {}

        // 校验未通过 非风险-弹出校验弹窗 高风险-跳转身份认证
        if (result === false) {
          if (riskType === RiskType.NON_RISK) {
            this.updateShowRiskModal(true)
            this.updateRiskInfo({ riskCopy, riskType, jumpLink })
          } else {
            const url = this.extractUrlsCombine(jumpLink, entrySource)
            navigateToByNative(url)
          }
        }
        return result
      } else {
        Toast.fail(msg || '请求失败，请稍后再试')
      }
    } catch (error) {
      Toast.fail('请求失败，请稍后再试')
    }
    return false
  }
  updateRiskInfo(info: RiskInfo) {
    this.riskInfo = info
  }
  updateShowRiskModal(value: boolean) {
    this.showRiskModal = value
  }
  extractUrlsCombine(urls: string, entrySource: string) {
    const paramIndex = urls.indexOf('?')

    if (paramIndex !== -1) {
      const params = urls.substring(paramIndex + 1)
      const parsedParams = queryString.parse(params)
      const encodeUrl = encodeURIComponent(`${parsedParams.url}?entrySource=${entrySource}`)
      const originalUrl = urls.substring(0, paramIndex)

      const finalUrl = `${originalUrl}?url=${encodeUrl}`
      return finalUrl
    } else {
      console.log('无效的URL')
      return ''
    }
  }
}

export default new MemberStore()
